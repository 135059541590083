<template>
  <div id="Login" class="loginbox">
    <v-container fluid>
      <v-row justify="center">
        <v-card max-width="100%" outlined :loading="loading || loginFailed">
          <template slot="progress">
            <v-progress-linear
              :active="loading"
              color="blue"
              indeterminate
            ></v-progress-linear>
            <v-progress-linear
              :active="loginFailed"
              color="red"
              value="100"
            ></v-progress-linear>
          </template>

          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title class="headline">
                <img
                  alt="Vue logo"
                  class="logo"
                  src="../assets/logo_ezcli.svg"
                />
              </v-list-item-title>
              <v-list-item-subtitle :color="message.color">{{
                message.text
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>

          <!-- <v-divider></v-divider>

      <v-card-text>
        APPLE, GOOGLE, FACEBOOK
      </v-card-text>

      <v-divider></v-divider> -->
          <div class="form-input">
            <validation-observer ref="observer" v-slot="{ invalid }"><v-form>
              <v-container>
                <v-row dense>
                  <v-col cols="12">

                    <validation-provider
                      v-slot="{ errors }"
                      name="email"
                      rules="required|email"
                    >
                      <v-text-field
                        :error-messages="errors"
                        v-model="email"
                        label="email"
                        reqired
                        outlined
                      ></v-text-field>
                    </validation-provider>
                    <validation-provider
                      v-slot="{ errors }"
                      name="password"
                      rules="required"
                    >
                      <v-text-field
                        :error-messages="errors"
                        v-model="password"
                        label="password"
                        name="password"
                        :type="showPassword ? 'text' : 'password'"
                        :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                        @click:append="show1 = !show1"
                        required
                        outlined
                      ></v-text-field
                    ></validation-provider>
                  </v-col>
                  <v-col>
                    <v-btn :disabled="invalid" @click="login()" outlined 
                      >log in</v-btn
                    >
                  </v-col>
                </v-row>
              </v-container></v-form></validation-observer
            >
          </div>
        </v-card></v-row
      ></v-container
    >
  </div>
</template>


<script>
import { required, email } from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";

setInteractionMode("eager");
extend("required", {
  ...required,
  message: "{_field_} can not be empty",
});
extend("email", {
  ...email,
  message: "provide a valid email address",
});
// wojtek@ezcli.com
export default {
  name: "Login",
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    msg: String,
  },
  data: () => ({
    message: {
      text: "log in",
      color: "black",
    },
    loading: false,
    loginFailed: false,
    email: "wojtek@ezcli.com",
    password: "",
    showPassword: false,
  }),
  methods: {
    login() {
      this.message.text = "authenticating";
      this.message.color = "black";
      this.loginFailed = false;
      this.loading = true;

      this.$store
        .dispatch("login", {
          email: this.email,
          password: this.password,
        })
        .then(
          () => {
            this.loading = false;
          },
          () => {
            setTimeout(() => {
              this.message.text = "failed to authenticate";
              this.message.color = "red";
              this.loading = false;
              this.loginFailed = true;

            }, 2500);
          }
        );
    },
  },
};
</script>

<style>
.loginbox {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>


